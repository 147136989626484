import $ from 'jquery';
import Preloader from './modules/preloader';
import './modules/gallery';


let wind        = $(window),
    menu        = $('.page-nav'),
    menuTrigger = $('.trigger'),
    menuActive  = 'scroll';


$(window).load(() => {
    Preloader.hide();
    wind.on('scroll', function() {
        let scrollPos  = $(this).scrollTop();
        scrollPos >= 10 ? menu.addClass(menuActive) : menu.removeClass(menuActive);
    });
});


$('[data-scroll]').click(function() {
    let target  = $(this).attr('data-scroll'),
        offset  = $(this).attr('data-scroll-offset') ? $(this).attr('data-scroll-offset') : 0;
    scrollToElem(target, offset);
});


function scrollToElem(elem, offs){
    $("html, body").animate({
        scrollTop: $(elem).offset().top-offs
    }, 300);
}


menuTrigger.on('click', () => {
    menu.toggleClass('open');
});


menu.find('li').each(function(){
    $(this).click(function(){
        menuTrigger.click()
    });
});
