import $ from 'jquery';


let $withCat        = $('.with-category'),
    withCatElem     = '.with-category li',
    catElem         = '.category > li';



let showCategory = (obj) => {
    let target = obj.attr('data-slug');
    $(withCatElem).hide();
    $(withCatElem + '.' + target).show();
    window.location.hash = target;
}

$(catElem).on('click', function(e){
    e.preventDefault();
    $(catElem).removeClass('active');
    $(this).addClass('active');
    showCategory($(this));
});

$(document).ready(() => {
    $('.category > li:nth-child(1)').click();
});