/** @module Preloader module */

const hide = function() {
    const preloader = document.getElementById('preloader');
    preloader.className = preloader.className + 'hide-me';
}

export default{
  hide: hide
}
